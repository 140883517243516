/**
 * Accepts 2 arrays and returns the matching items in the array
 * @param arr1
 * @param arr2
 */
export function diff(arr1: string[], arr2: string[]): string[] {
    const ret: string[] = [];
    arr1.sort();
    arr2.sort();
    for (let i = 0; i < arr1.length; i += 1) {
        if (arr2.indexOf(arr1[i]) > -1) {
            ret.push(arr1[i]);
        }
    }
    return ret;
}

/*
 * accepts array of strings and filters it for uniqueness
 */
export function uniq(list: string[]) {
    return list.filter((item, pos) => list.indexOf(item) === pos);
}
