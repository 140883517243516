import { DateTimeRange } from 'lib/types/range';

const extractDateString = (date: string): string | null => {
    const regex = /'(.*?)'/; // everything between single quotes
    const dateString = date.match(regex);
    if (dateString) {
        return dateString[1];
    }
    return null;
};

const isLowerUnbounded = (rangeString: string): boolean =>
    rangeString.charAt(1) === ',';

const isUpperUnbounded = (rangeString: string): boolean =>
    rangeString.charAt(rangeString.length - 2) === ',';

export const deserializeRange = (rangeString: string): DateTimeRange => {
    const rangeArr = rangeString.split(',');
    return {
        lower: isLowerUnbounded(rangeString)
            ? {
                  date: null,
                  inclusive: null,
              }
            : {
                  date: extractDateString(rangeArr[0]),
                  inclusive: rangeString.charAt(0) === '[',
              },
        upper: isUpperUnbounded(rangeString)
            ? {
                  date: null,
                  inclusive: null,
              }
            : {
                  date: extractDateString(rangeArr[1]),
                  inclusive: rangeString.charAt(rangeString.length - 1) === ']',
              },
    };
};

export default deserializeRange;
